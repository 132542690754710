@font-face {
    font-family: gintronic;
    src: url('../fonts/c59ee803ebdeddbc5a7962648bcaa5e6.ttf') format('truetype');
}

@tailwind base;
@tailwind components;
@tailwind utilities;


@media (max-width: 640px) {

    button,
    a,
    p,
    span {
        font-size: 10px;
    }
    h3{
        font-size: 10px !important;
    }
  

}

body {
    background-image: url("../images/wallpaperflare.com_wallpaper.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    font-family: gintronic;
    font-weight: 700;

    a {
        text-decoration: none;
    }


}




nav {
    position: fixed;
    top: 0;
    z-index: 1000;

    a,
    button {
        color: white;
    }

    button {
        box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
    }

    .active {
        font-weight: bold;
        border-bottom: 2px #00adb9 solid;
        animation: opacity .4s;
    }

    &.bg {
        background: url('../images/wallpaperflare.com_wallpaper.jpg');
        background-size: cover;
        background-repeat: no-repeat;
    }

    @keyframes opacity {
        from {
            transform: scale(0.9);
        }

        to {
            transform: scale(1);
        }
    }

}

.home {
    h1 {
        filter: drop-shadow(1px 1px 5px #ffffff);
        font-size: 100px;
        font-weight: bolder;
        color: white;
        text-shadow: 0 1px #8da1ff, -1px 0 #c0cbff, -1px 2px #8da1ff, -2px 1px #c0cbff,
            -2px 3px #8da1ff, -3px 2px #c0cbff, -3px 4px #8da1ff, -4px 3px #c0cbff,
            -4px 5px #8da1ff, -5px 4px #c0cbff, -5px 6px #8da1ff, -6px 5px #c0cbff,
            -6px 7px #8da1ff, -7px 6px #c0cbff, -7px 8px #8da1ff, -8px 7px #c0cbff;
    }
    h1,
    h3,
    p,
    a,
    li {
        color: white;
        transition: .3s;


    }
    a:hover {
        color: #ff6e6e;
    }
    .top {

        h3 {
            color: white;
        }

        .image-container {
            display: inline-block;
            position: relative;

            img {
                z-index: 2;
                display: block;
                width: 100%;
                height: auto;
                border-radius: 50%;
                /* Use 50% for a circular shape, adjust as needed */
                clip-path: circle(50% at center);
                /* Clip the image to a circle */
                animation: color 10s infinite alternate;
                ;

                @keyframes color {
                    from {
                        filter: drop-shadow(5px 10px 20px #ff6e6e);
                    }

                    to {
                        filter: drop-shadow(5px 10px 20px #6f24f8);
                    }
                }
            }

            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background: rgba(0, 0, 0, 0.1);
                /* Shadow color with transparency */
                border-radius: 50%;
                /* Ensure the shadow is also circular */
                z-index: -1;
                /* Behind the image */
            }

            .circle {
                filter: drop-shadow(5px 1px 10px #ffffff);
                z-index: -1;
                width: 20%;
                height: 20%;
                background: radial-gradient(circle at 100px 300px, #ffffff, #ffffff);
                border-radius: 50%;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                overflow: hidden;
                margin: auto;

            }



        }

        .icons {
            position: absolute;
            right: 0;
            top: 50%;

            .icon,
            .icon-text {
                box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
                padding: 5px;
                border-radius: 3px;
                color: white;
            }
            .icon-content{
                transition: 0.3s;
                &:hover{
                    transform: scale(1.1);
                }
            }
            

        }
        .map {
            img {
                transform: scale(1) !important;
                width: 100px;
                height: 100px;
                filter: drop-shadow(1px 10px 20px #ff6e6e);
                transition: .3s;

                &:hover {
                    transform: scale(1.2) !important;
                }

            }

        }


    }

    .middel {
        .col {
            min-height: 30vh;
            box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
            border-radius: 5px;
        }
    }

    .about {

        .certifications {

            img {
                border-radius: 5px;
            }

            .certification {
                box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
                border-radius: 5px;
            }
            

        }
    }
    .projects{
        .image-container{
            overflow-x: auto;
            max-width: 50%;
            overflow: hidden;
        }
          .img {
            transition: transform 0.3s;
            width: 600px;
            box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;

            
          }
          .zoomed{
            transform: scale(1.3) !important;
          }
     
          p {
            transition: transform 0.3s;
            backdrop-filter: blur(2px);
            z-index: 500;
          }
          
          
    }
    .contact-me{
        margin: 150px 0;
    }
}

form{
    position: relative;
    z-index: 500;
}
.random-icons {
    overflow: hidden;
    position: absolute;
    width: 100%;
    height: 100vh;

    .row {
        filter: drop-shadow(1px 1px 10px #ffffff);
        width: 50px;
        height: 50px;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 50px;
        cursor: grab;
        user-select: none;
    }

    .react-icon {
        animation: rotation-animation 2s linear infinite;

        @keyframes rotation-animation {
            from {
                transform: rotate(0deg);
            }

            to {
                transform: rotate(360deg);
            }
        }
    }

    .react-icon-speed {
        animation: rotation-animation 1.5s linear infinite;

        @keyframes rotation-animation {
            from {
                transform: rotate(0deg);
            }

            to {
                transform: rotate(360deg);
            }
        }
    }

    .react-icon-super-speed {
        animation: rotation-animation 0.5s linear infinite;

        @keyframes rotation-animation {
            from {
                transform: rotate(0deg);
            }

            to {
                transform: rotate(360deg);
            }
        }
    }
}